.tooltip-event {
  position: absolute;
  display: contents;
  z-index: 100;
  left: 1044px;
  top: 500px;
  background-color: #fcfcfc;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  padding: 3.5px 7px;
}

.tooltip-event h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 15.5px;
}

.tooltip-event p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 18px;
}
